
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CarrouselWrapper,
CarrouselWrapper2,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ExpoWrapper
} from "./SamFrancis.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import EROPhoto1 from "../../../../res/Photos site/ERO/ero.png"
import EROPhoto2 from "../../../../res/Photos site/ERO/People-Magazine-comes-to-the-FUN-1983.-Photo-courtesy-of-Patti-Astor.-759x1024.jpg"
import EROPhoto3 from "../../../../res/Photos site/ERO/3.jpg"
import EROPhoto4 from "../../../../res/Photos site/Photos Oeuvres Disponibles/Ero - Untitled - 1984.png"
import CarrouselCartel from "../../../../components/CarrouselCartel";
import Button from "./../../../../components/Button";
import Pdp from "../../../../res/francis/portrait.jpeg"
import PastPresent1 from "../../../../res/francis/past-present-1.png"
import PastPresent2 from "../../../../res/francis/past-present-2.png"
import PastPresent3 from "../../../../res/francis/past-present-3.jpg"
const isBrowser = typeof window !== "undefined";
const infos ={
  path: "don-leicht",
  name: "DON LEICHT",
  description: "Sam Francis est un artiste américain né le 25 juin 1923, connu pour son abstraction picturale fortement influencée par Jackson Pollock, chef de file de l’expressionnisme abstrait américain. Proche du mouvement de l’art informel lors de son passage à Paris dans les années 1950, Sam Francis se distingue par ses toiles construites par éclaboussures et jouant avec les espaces blancs et les bords de la toile. Mort en 1994 à l’âge de 71 ans, il est l’un des fondateurs du Los Angeles Museum of Contemporary Art et ses oeuvres se trouvent notamment au sein des collections du Met et du MoMA de New York, du Kunst museum de Bâle et du Centre Pompidou à Paris. ",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Don Leicht.",
  photos: [
    { src: PastPresent1, name: "ERO" },
    { src: PastPresent2, name: "ERO" },
    { src: PastPresent3, name: "ERO" },
  ],
  citations: []
};

const infos2 = {
  photos: [
    // { src: DanielArsham1, name: "Daniel Arsham", artist: "Daniel Arsham (1980)", title:"Ash Eroded Film Reel, 2013", desc1:"Cendres volcaniques, verre brisé, hydrostone", desc2:"Volcanic ash, shattered glass, hydrostone", desc3:"35 x 35cm", desc4:"14 7⁄8 x 14 7⁄8 in.", desc5:"Sculpture originale, pièce unique", desc6:"Original sculpture, unique work", desc7: "- Collection particulière.\n- Ron Mandos Gallery, Pays-Bas.", desc8:"<h4>-<i> Nos Fantômes - </i>exposition inaugurale, Ghost galerie, Paris, France. 10 Septembre - 20 Novembre, 2021</h4>", desc10:"<h4>- Un certificat de Ron Mandos Gallery sera remis à l'acquéreur.</h4>"  }
    { src: EROPhoto4, name: "ERO", artist:"ERO\n(Dominique Philbert dit) (1967 - 2011)", title:"Untilted, 1984", desc3: "120 x 254 cm", desc4:" 47 1/4 x 100 in.", desc7: "- Collection particulière." },
  ],
};

const SamFrancis = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>SAM FRANCIS<h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <h3 className={NameWrapper} >1923 - 1994</h3>
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
          <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }}
            >
              Contactez-nous
            </Button>
        </div>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           
        </div>
      </div>
    </div>
  );
};

export default SamFrancis;