// extracted by mini-css-extract-plugin
export var ArtistDescription = "SamFrancis-module--ArtistDescription--eWYK7";
export var ButtonWrapper = "SamFrancis-module--ButtonWrapper --J0j86";
export var CardWrapper = "SamFrancis-module--CardWrapper--rrLhh";
export var CarrouselWrapper = "SamFrancis-module--CarrouselWrapper--qbped";
export var CarrouselWrapper2 = "SamFrancis-module--CarrouselWrapper2--hUvUt";
export var DescriptionWrapper = "SamFrancis-module--DescriptionWrapper--FnrCd";
export var ExpoWrapper = "SamFrancis-module--ExpoWrapper--1G1S-";
export var LinkWrapper = "SamFrancis-module--LinkWrapper--S13jz";
export var NameWrapper = "SamFrancis-module--NameWrapper--snQUA";
export var PdpWrapper = "SamFrancis-module--PdpWrapper--qFjKv";
export var PhotosWrapper = "SamFrancis-module--PhotosWrapper--ZwGFr";
export var ProfilWrapper = "SamFrancis-module--ProfilWrapper--2ouKa";
export var TitleWrapper = "SamFrancis-module--TitleWrapper--6g+yX";
export var VideoWrapper = "SamFrancis-module--VideoWrapper--c2sPK";
export var VideosWrapper = "SamFrancis-module--VideosWrapper--FJSW4";
export var WorkWrapper = "SamFrancis-module--WorkWrapper--9tRsj";
export var WorksWrapper = "SamFrancis-module--WorksWrapper--PFpss";
export var Wrapper = "SamFrancis-module--Wrapper--mGNev";